<script setup lang="ts">
import { Button, IconBell } from '~/components/5x';

import { injectNotificationsContext } from '../context';

const { isDesktop } = useMobile();

const { unreadCounts } = injectNotificationsContext();

const isMore99 = computed<boolean>(() => Number(unreadCounts.value) > 99);
</script>

<template>
  <Button color="neutral" size="icon-md" :variant="isDesktop ? 'outline' : 'ghost'" class="relative">
    <IconBell v-if="isDesktop" />
    <IconBell v-else size="24" thickness="light" />
    <div
      v-if="unreadCounts"
      :class="{ 'left-4.5': !isMore99, 'left-4': isMore99 }"
      class="absolute top-[3px] flex justify-center items-center text-neutral-000 text-[11px] font-bold bg-red-600 px-[5px] h-4 rounded-full">
      {{ isMore99 ? '99+' : unreadCounts }}
    </div>
  </Button>
</template>
