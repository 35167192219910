<script setup lang="ts">
import Tracker, { type ClickMyClassroomMenuProps, type ClickSignupEventProps } from '~/libs/Tracker';

import Utility from '~/services/Utility';
import { useBasicsStore } from '~/services/base';
import { useCommunityStore } from '~/services/community';
import { useUserGnbStore } from '~/services/gnb';
import { useUserAuthStore } from '~/services/userAuth';

import { Button } from '~/components/5x';

import TeleportCommunityList from './TeleportCommunityList.vue';

const authStore = useUserAuthStore();
const gnbStore = useUserGnbStore();
const communityStore = useCommunityStore();
const router = useRouter();
const route = useRoute();
const basicStore = useBasicsStore();

const INIT_STATE = () => ({
  title: 'CLASS',
});

const state = reactive(INIT_STATE());

const emit = defineEmits<{
  (e: 'menu-toggle', param: any): void;
}>();

const clickMyPage = (event) => {
  if (route.name === 'mypage-index') {
    emit('menu-toggle', event);
  } else {
    router.push('/mypage');
  }
};

const setTabClass = (active: boolean) => {
  if (active) {
    return 'active-tab-item';
  }
  return 'inactive-tab-item';
};

const setTabIcon = (isClass: boolean, active: boolean) => {
  if (isClass) {
    if (active) {
      return '/icon/ClassBlack.png';
    }
    return '/icon/ClassGray.png';
  } else {
    if (active) {
      return '/icon/ChatBlack.png';
    }
    return '/icon/ChatGray.png';
  }
};

const eventParams: ClickSignupEventProps = {
  location: 'hamburger',
  url: process.client ? window.location.href : '',
  path: route.path,
};

const navToSignup = () => {
  Tracker['Click Signup Button'](eventParams);
  basicStore.setLoginModalState(true);
};

const navToLogin = () => {
  Tracker['Click Login Button'](eventParams);
  basicStore.setLoginModalState(true);
};

const clickClassTab = () => {
  state.title = 'CLASS';
};

const clickCommunityTab = () => {
  state.title = 'COMMUNITY';
};

const onGoToClassroom = async () => {
  /**
   * 변경된 예시
    url : https://weolbu.com/community?focus=N&ref=869941
    path: /community
   */
  // NOTE: prevRoute, prevPath가 상황에 따라 뒤집히는 이슈가 발생...
  const prevRoute = sessionStorage.getItem('prevRoute');
  const prevPath = sessionStorage.getItem('prevPath');
  const eventParams: ClickMyClassroomMenuProps = {
    location: 'hamburger',
    path: prevPath,
    url: prevRoute,
  };
  Tracker['Click My Classroom Menu'](eventParams);

  router.push('/mypage/studyroom');
};

const setNickname = () => {
  if (!authStore?.user?.nickName) {
    return authStore?.user?.userName;
  }
  return authStore?.user?.nickName;
};

const handleClickEvent = (btnTitle: string) => {
  Tracker['Click Hamburger Category']({ text: btnTitle });
};
</script>

<template>
  <div id="wb-mobile-header-teleport" class="mobile-menu-as-modal">
    <!-- 모바일 2depth -->
    <div class="wb-header-menu overflow-visible">
      <div v-if="!authStore.isAuthenticated" class="header-wrapper">
        <div class="flex items-center gap-3 text-base text-neutral-1000">
          <div class="font-bold" @click="navToLogin">로그인</div>
          <span class="font-light opacity-20">ㅣ</span>
          <div class="text-primary-600 font-bold" @click="navToSignup">회원가입</div>
        </div>

        <img
          class="close-button"
          src="/icon/CloseBlackX.png"
          alt="close button"
          @click="
            (e) => {
              emit('menu-toggle', e);
            }
          " />
      </div>

      <div v-else class="header-wrapper">
        <div class="profile-image-set text-base text-neutral-1000 gap-3" @click="clickMyPage">
          <img
            class="profile-image"
            :src="
              authStore?.user?.userProfileImgUrl
                ? Utility.getOptimized(authStore?.user?.userProfileImgUrl, 'PROFILE_IMG')
                : '/temp/user.svg'
            "
            loading="lazy"
            alt="profile img" />
          <p class="font-bold">{{ setNickname() }}</p>
          <span class="font-light opacity-20">ㅣ</span>
          <div class="text-primary-600 font-bold" @click="onGoToClassroom">내 강의실</div>
        </div>

        <img
          class="close-button"
          src="/icon/CloseBlackX.png"
          alt="close button"
          @click="
            (e) => {
              emit('menu-toggle', e);
            }
          " />
      </div>

      <div class="relative z-10">
        <div class="flex">
          <div
            class="flex flex-grow justify-center items-center gap-2.5 pt-2 pb-3 border-b-4 border-solid"
            :class="state.title === 'CLASS' ? 'border-neutral-1000' : 'border-[#f2f2f2]'"
            @click="clickClassTab">
            <img class="tab-icon" :src="setTabIcon(true, state.title === 'CLASS')" />
            <p class="text-lg text-[#aaaaaa]" :class="{ 'text-black font-bold': state.title === 'CLASS' }">클래스</p>
          </div>
          <div
            class="flex flex-grow justify-center items-center gap-2.5 pt-2 pb-3 border-b-4 border-solid"
            :class="state.title !== 'CLASS' ? 'border-neutral-1000' : 'border-[#f2f2f2]'"
            @click="clickCommunityTab">
            <img class="tab-icon" :src="setTabIcon(false, state.title !== 'CLASS')" />
            <p class="text-lg text-[#aaaaaa]" :class="{ 'text-black font-bold': state.title !== 'CLASS' }">커뮤니티</p>
          </div>
        </div>

        <div v-if="state.title === 'CLASS'" class="flex flex-col px-5 py-4">
          <div v-for="(parent, index) in gnbStore.getUserGnbTree" :key="`menu-${parent.cateSeq}`">
            <div class="grid grid-cols-2 gap-0.5">
              <a
                v-for="child in parent.items"
                :key="`menu-${parent.cateSeq}-${child.cateSeq}`"
                class="py-2 text-sm font-medium text-neutral-1000"
                @click="
                  Utility.onMovePageWithEvent(child.to as string, {
                    categoryId: `${child.cateSeq}`,
                    className: child.label,
                  })
                ">
                {{ child.label }}
              </a>
            </div>
            <div v-if="index !== gnbStore.getUserGnbTree?.length - 1" class="w-full h-[1px] bg-neutral-200 my-2"></div>
          </div>
        </div>
        <TeleportCommunityList
          v-else
          @menu-toggle="
            (e) => {
              emit('menu-toggle', e);
            }
          " />
      </div>
      <div class="h-4 bg-neutral-200 border-t border-solid border-[rgba(0,0,0,0.05)]"></div>
      <div class="flex items-center gap-0.5 px-5 pt-3 pb-[76px]">
        <div class="flex flex-grow gap-7 py-2">
          <a
            class="text-sm font-normal text-neutral-700"
            href="https://b2b.weolbu.com"
            target="_blank"
            @click="handleClickEvent('기업교육')">
            기업교육
          </a>
          <a
            v-if="!authStore.isAuthenticated"
            class="text-sm font-normal text-neutral-700"
            href="https://docs.google.com/forms/d/e/1FAIpQLScfpPlTQVkq1HG6qqnOvc2AJEFFu1mqrS61KamxMDR57mxpsA/viewform"
            target="_blank"
            @click="handleClickEvent('강사 지원하기')">
            강사 지원하기
          </a>
        </div>
        <CustomLink
          to="https://weolbu.channel.io/support-bots/95098"
          target="_blank"
          @click="handleClickEvent('1:1 학습 컨설팅')">
          <Button color="neutral" size="sm" variant="solidLight">1:1 학습 컨설팅</Button>
        </CustomLink>
      </div>
    </div>
    <!-- //모바일 2depth -->
  </div>
</template>

<style scoped>
.mobile-menu-as-modal {
  position: relative;
  z-index: 1000;
  background: white;
}

.header-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: #fff;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding-left: 20px;
  padding-right: 20px;
}

.vertical-divider {
  height: 18px;
}

.button-text {
  font-size: 18px;
  font-weight: 700;
}

.close-button {
  width: 22px;
  height: 22px;
}

.profile-image-set {
  display: flex;
  align-items: center;
  /* gap: 12px; */
  cursor: pointer;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.nickname {
  font-size: 16px;
  font-weight: 700;
  margin-left: 10px;
  & ~ span {
    display: inline-block;
    color: #00000014;
    padding: 0 8px;
    & + button {
      padding: 0;
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.arrow-right {
  width: 6px;
  height: 12px;
  cursor: pointer;
}

.menu-set-wrapper {
  display: flex;
  margin-top: 20px;
  width: calc(100% + 32px);
  margin-left: -16px;
}

.menu-set-tab-item {
  width: 50%;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 8px;
  border-bottom: 4px solid;
  padding-bottom: 8px;
}

.active-tab-item {
  color: #000000;
  border-color: #000000;
}

.inactive-tab-item {
  color: #aaaaaa;
  border-color: #f2f2f2;
}

.tab-icon {
  width: 20px;
  height: 20px;
}
</style>
