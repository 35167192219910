<script setup lang="ts">
import Tracker, { type ClickBoardItemProps } from '~/libs/Tracker';
import { safeLocalStorage } from '~/libs/safeLocalStorage';

import Utility from '~/services/Utility';
import { useCommonStore } from '~/services/common';
import { mainService, useMainStore } from '~/services/main';
import {
  Notifications,
  NotificationsBellButton,
  RightSheet,
  RightSheetContent,
  RightSheetTrigger,
} from '~/services/notifications';
import { NotificationPermissionBottomModal, usePwaStore } from '~/services/pwa';
import { useUserAuthStore } from '~/services/userAuth/store';

import { StorageItem } from '~/shared/StorageItem';

import SigninSet from '~/components/domain/main/header/SigninSet.vue';
import AppHeaderAlarm from '~/components/layouts/default/AppHeaderAlarm.vue';
import AppHeaderGNB from '~/components/layouts/default/AppHeaderGNB.vue';
import AppHeaderSearch from '~/components/layouts/default/AppHeaderSearch.vue';

import { requestPermission } from '../../../libs/firebaseConfig';
import TeleportMenuSet from '../../domain/main/teleport/TeleportMenuSet.vue';

const isClient = $isClient;
const runtime = useRuntimeConfig();
const router = useRouter();
const route = useRoute();
const mainStore = useMainStore();
const authStore = useUserAuthStore();
const commonStore = useCommonStore();
const pwaStore = usePwaStore();
const { isMobile, isDesktop } = useMobile();
const { isPwa, os } = usePwa();
const isEmpty = $isEmpty;
const showNotificationPopup = ref(false);
const currentPath = computed(() => route.path);

const isLoggedIn = computed<boolean>(() => !!authStore.user);

const emit = defineEmits<{
  (e: 'menu-toggle', param: any): void;
  (e: 'search-toggle', param: any): void;
}>();

const topBanner = computed(() => {
  const bannerList = mainStore.designModuleMainList.items
    .filter((v) => v.useYn === 'Y')
    .filter((v) => v.designModuleType === 'BANNER' && v.designType === 'MAIN');
  if (isEmpty(bannerList)) {
    return null;
  }
  return bannerList[0];
});

const menuDepthToggleMobile = (event: any) => {
  emit('menu-toggle', event);
};

const handleScroll = () => {
  const wbContents = document.getElementById('wb-contents') as any;
  const scrollPosition = window.scrollY;
  const headerHeight = (document.querySelector('.wb-header__top') as any)?.offsetHeight;
  if (scrollPosition + headerHeight >= wbContents.offsetTop) {
    mainStore.headerSticky = true;
  } else {
    mainStore.headerSticky = false;
  }
};

const handleClick = () => {
  if (route.path === '/') {
    location.reload();
  } else {
    router.push('/');
  }
};

const clickBoardItemEvent = () => {
  const clickBoardItem: ClickBoardItemProps = {
    pageName: 'COMMUNITY',
    location: 'GNB',
    gnbType: 'community',
    gnbId: '',
    gnbName: '',
    boardId: '',
    boardName: '',
    url: process.client ? window.location.href : '',
    path: route.path,
  };
  Tracker['Click Navigation'](clickBoardItem);
};

const trackCommunityEvent = () => {
  clickBoardItemEvent();
};

router.beforeEach(async () => {
  const userAuthData = StorageItem.local('user-auth').get();
  if (!userAuthData) {
    return;
  }
  try {
    if (!authStore.user) {
      if (!userAuthData) {
        throw new Error('No user auth data');
      }
      const user = JSON.parse(userAuthData);
      if (!user.token) {
        throw new Error('Token is null');
      }
      await authStore.fetchUserInfo();
    }
  } catch (err) {
    console.error(err);
  }
});

const clickViewListEvent = () => {
  const prevUrl = window.history.state.back;
  const currentUrl = window.history.state.current;
  const splittedUrl = currentUrl.split('?');

  Tracker['View Community List']('', {
    referrer_url: prevUrl,
    referrer_path: splittedUrl[0],
    boardId: '',
    gnbId: '100000',
    gnbName: '홈',
  });
};

const setHideBorderBySticky = () => {
  const isProductPath = currentPath.value.includes('/product');
  return !mainStore.headerSticky && isProductPath;
};

const sendDeviceToken = async () => {
  if (isPwa.value && Notification.permission === 'granted') {
    const token = await requestPermission();
    if (token) {
      mainService.postUserDeviceToken({
        deviceToken: token,
        os: os.value,
        isPwa: isPwa.value,
      });
    }
    updatePWAPopupPeriod('showNotificationPopup', 7);
  }
};

watch(
  [authStore.user, isPwa],
  (val) => {
    if ($isClient() && !('Notification' in window)) {
      console.log('This browser does not support notification');
      return;
    }
    if (!$isEmpty(val[0])) {
      if (Notification.permission === 'default') {
        showNotificationPopup.value = isPwa.value && safeLocalStorage.getItem('showNotificationPopup') === null;
      } else {
        sendDeviceToken();
      }
    }
  },
  { deep: true, immediate: true },
);

watch(
  topBanner,
  (topB) => {
    if (topBanner.value == null) {
      return;
    }
    mainStore.visibleTopBanner = !Utility.hasCachedInOneDay(topBanner.value!.designSeq, 'designSeq');
  },
  { immediate: true },
);

watch(
  () => route.path,
  (val) => {
    if (val === '/community') {
      clickViewListEvent();
    }
  },
);

onServerPrefetch(async () => {
  try {
    await Promise.all([mainStore.fetchGnbMenuList(), mainStore.fetchQuickMenuList()]);
  } catch (err) {
    console.error(err);
  }
});

onBeforeMount(async () => {
  try {
    await Promise.all([mainStore.fetchGnbMenuList(), mainStore.fetchQuickMenuList()]);
  } catch (err) {
    console.error(err);
  }
});

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
  setTimeout(() => {
    const element02 = document.getElementById('btnMypageMo');
    if (element02) {
      element02.focus();
    }
  }, 1000);

  if (isMobile.value && !isPwa.value && document && document.querySelector('#manifest-link')) {
    document.querySelector('#manifest-link')?.setAttribute('href', '/manifest.json');
  }

  // 개발 환경에서 url 에 ?c=true 입력시, 저장된 캐시 삭제 - 인앱브라우저에서는 캐시 삭제가 어려워서 추가
  if (runtime.public.app_env !== 'prod' && route.query?.pp) {
    safeLocalStorage.removeItem('showInstallBottomModal');
    alert(`PWA 팝업 캐시 삭제: ${safeLocalStorage.getItem('showInstallBottomModal')}`);
  }
  // alert(`authStore.cachedPopupData: ${JSON.stringify(authStore.cachedPopupData)}`);
  // 개발 환경에서 url 에 ?b=true 입력시, 저장된 캐시 삭제 - 인앱브라우저에서는 캐시 삭제가 어려워서 추가
  if (runtime.public.app_env !== 'prod' && route.query?.mp) {
    authStore.cachedPopupData = {};
    alert(`메인 팝업 캐시 삭제: ${JSON.stringify(authStore.cachedPopupData)}`);
  }
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<template>
  <!-- * TODO: 삼항연산자 코드 개선 필요(가독성 떨어짐) -->
  <div
    v-if="
      isMobile
        ? route.path.startsWith('/mypage') ||
          route.path.startsWith('/profile') ||
          route.path.startsWith('/search') ||
          route.path.startsWith('/notifications')
          ? commonStore.getMobileGnbViewList.includes(route.name)
          : true
        : true
    "
    class="wb-header__top"
    :class="{ 'wb-header__top_hide': setHideBorderBySticky() }">
    <div class="wb-inner">
      <div class="wb-header__top-start">
        <span
          @click="
            Tracker['Click Navigation']({
              pageName: '로고',
            })
          ">
          <NuxtLink :prefetch="false" class="wb-header__logo" @click="handleClick">월급쟁이부자들</NuxtLink>
        </span>
        <div class="wb-header__title">
          <NuxtLink
            :prefetch="false"
            style="cursor: pointer"
            :class="{
              active:
                route.name == 'index' ||
                route.name == 'class' ||
                route.name == 'class-event' ||
                route.name == 'curriculum' ||
                route.path.startsWith('/class'),
            }"
            @click="
              () => {
                Tracker['Click Navigation']({
                  pageName: '클래스',
                });
                Utility.onMovePage('/');
              }
            ">
            클래스
          </NuxtLink>
          <NuxtLink
            :prefetch="false"
            style="cursor: pointer"
            :class="{ active: route.path.startsWith('/community') }"
            :to="`/community?tab=100000`"
            @click="
              () => {
                trackCommunityEvent();
              }
            ">
            커뮤니티
          </NuxtLink>
        </div>
      </div>
      <div class="wb-header__top-end">
        <AppHeaderSearch @search-toggle="(event: any) => emit('search-toggle', event)" />

        <template v-if="isLoggedIn">
          <CustomLink v-if="!isDesktop" :to="$getRoute().notifications()">
            <NotificationsBellButton />
          </CustomLink>

          <RightSheet v-else>
            <RightSheetTrigger as-child>
              <NotificationsBellButton />
            </RightSheetTrigger>
            <RightSheetContent>
              <div class="overflow-y-auto h-full isolate">
                <Notifications />
              </div>
            </RightSheetContent>
          </RightSheet>
        </template>

        <SigninSet />

        <!-- 모바일 메뉴 2depth 시작 -->
        <div v-if="isClient()" class="wb-header__menu">
          <Button severity="secondary" outlined icon="pi pi-bars" @click="menuDepthToggleMobile" />
        </div>
        <!-- 메뉴 종료 -->
      </div>
    </div>
  </div>

  <AppHeaderGNB />

  <div class="wb-header-page">
    <div class="wb-header-page-community-write">
      <div class="wb-inner mx-0">
        <NuxtLink :prefetch="false" class="wb-header__logo" @click="handleClick">월급쟁이부자들</NuxtLink>
      </div>
    </div>
  </div>

  <NotificationPermissionBottomModal v-if="showNotificationPopup" />

  <ClientOnly>
    <TeleportMenuSet
      @menu-toggle="
        (e) => {
          emit('menu-toggle', e);
        }
      " />
  </ClientOnly>
</template>

<style lang="scss" scoped>
#app-header {
  position: sticky;
  top: 0;
  background-color: var(--neutral-000);
  z-index: 1000;
}
.wb-header__top_hide {
  border-bottom: unset;
}

.summer-discount a {
  display: flex;
  align-items: center;
  gap: 2px;
}
</style>
