import { safeDateParse } from '~~/src/libs/dateUtils';
import { safeLocalStorage } from '~~/src/libs/safeLocalStorage';

export type CashworkUserInfo = {
  firstVisit: string;
  closePopupDate: string | null;
};

export const STORAGE_KEY = 'cashwalk-2410';
export const UTM_KEY = 'cashwalk';
export const WEOLBU_STUDY_DISPLAYSEQ = 3765;
export const DUE_DATE = '2024-10-24T16:59:59+09:00';
export const EXCEPTION_PATH = ['/authorize'];

export const getCashworkUserInfo = () => {
  if (!$isClient()) {
    return null;
  }

  return (safeLocalStorage.getItem(STORAGE_KEY) || null) as CashworkUserInfo | null;
};

export const setCashworkUserInfo = (timestamp: string) => {
  if (!$isClient()) {
    return;
  }

  safeLocalStorage.setItem(STORAGE_KEY, { firstVisit: timestamp, closePopupDate: null });
};

export const getTestableDueDate = (testDate: string) => {
  const runtime = useRuntimeConfig();

  if (runtime.public.app_env !== 'prod') {
    return safeDateParse(testDate || DUE_DATE);
  }
  return safeDateParse(DUE_DATE);
};

export const getTestableDisplaySeq = (testSeq: string | number) => {
  const runtime = useRuntimeConfig();

  if (runtime.public.app_env !== 'prod') {
    return testSeq || WEOLBU_STUDY_DISPLAYSEQ;
  }
  return WEOLBU_STUDY_DISPLAYSEQ;
};
