<script setup lang="ts">
const props = defineProps<{
  text: string;
  subText: string;
  align: 'left' | 'right';
  iconEnabled: boolean;
  clickPath: string;
}>();
</script>

<template>
  <p :style="`white-space: pre-line; justify-content: ${props.align === 'right' ? 'space-between' : 'flex-start'}`">
    <object v-if="props.iconEnabled" data="/icon/circle-check-filled.svg" alt="circle-check-filled" />
    {{ props.text }}
    <img v-if="props.clickPath" src="/icon/arrow-right-tail-gray.svg" />
    <span v-else-if="props.subText">
      {{ props.subText }}
    </span>
  </p>
</template>

<style scoped></style>
