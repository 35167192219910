<script setup lang="ts">
import Tracker from '~~/src/libs/Tracker';
import { safeLocalStorage } from '~~/src/libs/safeLocalStorage';
import Utility from '~~/src/services/Utility';
import { getTestableDisplaySeq } from '~~/src/services/event/constants/cashwalk2410';
import { LinkType } from '~~/src/services/main/type';

const props = defineProps<{ isOpen: boolean }>();

const route = useRoute();

const isVisible = ref(props.isOpen);

const closePopup = () => {
  isVisible.value = false;
};

const movePage = () => {
  closePopup();
  Tracker['Click Popup']({
    popupType: 'cashwalk-zero',
    popupUrl: location.href,
  });

  const url = `https://weolbu.com/product?displaySeq=${getTestableDisplaySeq(safeLocalStorage.getItem('cashwalk-seq'))}`;
  Utility.openLink(url, LinkType['P']);
};

watch(
  () => [isVisible],
  () => {
    if (!isVisible.value) {
      return;
    }
    Tracker['View Popup']({ popupType: 'cashwalk-zero' });
  },
  { immediate: true },
);
</script>
<template>
  <Dialog :visible="isVisible" :modal="true" class="wb-dialog-main min-h-[150px]">
    <div class="wb-dialog-main__contents hover:cursor-pointer max-w-[400px] max-h-[400px]" @click="movePage">
      <img src="/images/cashwork/cashwork_202410.png" class="object-cover object-center" alt="" loading="lazy" />
    </div>
    <div class="wb-dialog-main__foot justify-end">
      <Button icon="pi pi-times" severity="secondary" text rounded @click="closePopup">
        <span class="p-button-icon"> </span>
      </Button>
    </div>
  </Dialog>
</template>

<style scoped>
.wb-dialog-main {
  min-width: 320px;
  max-width: 1080px;
  overflow: hidden;
  border: 1px solid rgba(9, 30, 66, 0.05);
  border-radius: 8px;
  @media screen and (max-width: $md) {
    max-width: calc(100% - 32px);
  }
  .p-dialog-content {
    padding: 0;
  }
  &__contents {
    cursor: pointer;
  }

  &__foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    padding-top: 0px;
    p {
      display: flex;
      align-items: center;
      cursor: pointer;

      .p-checkbox {
        min-width: 32px;
        width: 32px;
        height: 32px;
      }

      label {
        margin: 10.5px 12px;
        color: #788194;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media screen and (max-width: $md) {
          margin: 11.5px 12px;
        }
      }
    }

    button:enabled {
      .p-button-icon {
        cursor: pointer;
        width: 40px;
        height: 40px;
        background: url('/icon/click-area.svg') no-repeat 50% 50%;
      }
      &:hover {
        border-radius: 0px;
        background: unset !important;
      }
    }
  }
}
</style>
