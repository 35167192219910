<script setup lang="ts">
import { useLevelStore } from '~/services/level/store';
import { useUserAuthStore } from '~/services/userAuth';

import LevelFloatingPopupImage from './LevelFloatingPopupImage.vue';

const isShownUp = ref<boolean | null>(null);
const isOpened = ref<boolean>(true);
const popup = ref<HTMLElement | null>(null);
const isMounted = ref(false);

const userStore = useUserAuthStore();
const levelStore = useLevelStore();
const router = useRouter();

const onClick = (level: number) => {
  switch (level) {
    case 1:
      router.push('/community?tab=100140');
      break;
    case 2:
      router.push('/community?tab=100141');
      break;
    case 3:
    case 4:
    case 5:
      router.push('/community');
      break;
    default:
      break;
  }

  levelStore.hidePopup();
};

const onClickClose = () => {
  levelStore.hidePopup();
};

watch(
  () => levelStore.popupVisible,
  (visible) => {
    isShownUp.value = true;
  },
);

onMounted(() => {
  isMounted.value = true;
});

const getTitleByLevel = (level: number) => {
  switch (level) {
    case 1:
      return 'Lv.1 마음은부자 배지를 받았어요 🎉';
    case 2:
      return 'Lv.2 천만부자 배지를 받았어요 🎉';
    case 3:
      return 'Lv.3 일억부자 배지를 받았어요 🎉';
    case 4:
      return 'Lv.4 십억부자 배지를 받았어요 🎉';
    case 5:
      return 'Lv.5 백억부자 배지를 받았어요 🎉';

    default:
      return 'Lv.1 마음은부자 배지를 받았어요 🎉';
  }
};
const getDescriptionByLevel = (level: number) => {
  switch (level) {
    case 1:
      return 'Lv.1의 2단계 등업을 축하해요.\n 3단계까지 게시글 조회 7개, 댓글 10개 남았어요!';
    case 2:
      return 'Lv.2의 1단계 등업을 축하해요!\n 월부챌린지로 부자들의 습관을 내 것으로 만드세요!';
    case 3:
      return 'Lv.3의 1단계 등업을 축하해요!\n 조회수 100을 받을 수 있는 글을 계속 작성해보아요!';
    case 4:
      return 'Lv.4의 1단계 등업을 축하해요!\n 크리에이터님, 좋은 글로 선한영향력을 나눠주세요!';
    case 5:
      return `Lv.5의 1단계 등업을 축하해요!\n 슈퍼 크리에이터 ${
        userStore.user?.nickName ?? userStore.user?.userName
      }님, 함께 해주셔서 고맙습니다.`;

    default:
      return `Lv.1의 2단계 등업을 축하해요.\n 3단계까지 게시글 조회 7개, 댓글 10개 남았어요!`;
  }
};

const getCtaLabel = (level: number) => {
  switch (level) {
    case 1:
      return '전문가칼럼 보러가기';
    case 2:
      return '월부챌린지 보러가기';
    case 3:
    case 4:
    case 5:
      return '커뮤니티홈 바로가기';
    default:
      break;
  }
};
</script>

<template>
  <div
    v-if="isMounted && levelStore.popupVisible"
    ref="popup"
    class="floating-popup"
    :class="{ show: levelStore.popupVisible, 'is-column': true }">
    <div class="floating-popup-wrapper" :class="{ collapsed: !isOpened, 'is-column': true }">
      <button type="button" class="chevron" @click="onClickClose">
        <img class="chevron" src="/icon/close_circle_grey.svg" />
      </button>

      <div class="icon-div">
        <LevelFloatingPopupImage :level="levelStore.popupTargetLevel" />
      </div>
      <p class="title">{{ getTitleByLevel(levelStore.popupTargetLevel) }}</p>
      <p class="description">{{ getDescriptionByLevel(levelStore.popupTargetLevel) }}</p>
      <Button
        class="floating-popup-login"
        :label="getCtaLabel(levelStore.popupTargetLevel)"
        size="small"
        @click="onClick(levelStore.popupTargetLevel)" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
$duration: 0.75s;
$collapse-duration: 0.44s;

@keyframes fade-in-up {
  0% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes out-down {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.floating-popup {
  position: fixed;
  bottom: 0px;
  padding: 20px 16px;
  padding-bottom: 48px;
  z-index: 100;
  width: 403px;
  opacity: 0;
  z-index: 999;
  transition: all $duration ease-in-out;
  transform: translateY(100%);
  visibility: none;

  &.is-column {
    position: sticky;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 992px) {
    left: 0;
    width: 100%;
    padding-bottom: 32px;
  }

  &.show {
    visibility: block;
    animation: fade-in-up $duration ease-in-out forwards;
    &.is-column {
      bottom: 40px;
      @media screen and (max-width: 992px) {
        margin-top: 20px;
      }
    }
  }

  &.hide {
    animation: out-down $duration ease-in-out forwards;
  }
}

.floating-popup-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 22px 20px;
  border-radius: 24px;
  background-color: white;
  box-shadow: 0px 2px 16px 0px #1c2a4b3d;
  transition: all $collapse-duration ease-in-out;
  width: 371px;
  min-height: 260px;
  overflow: hidden;

  &.is-column {
    position: absolute;
  }

  bottom: 0px;
  left: calc((100% - 1200px) / 2);

  @media screen and (max-width: 1200px) {
    left: 16px;
  }

  @media screen and (max-width: 575px) {
    // mobile
    left: 16px;
    width: calc(100% - 32px);
  }

  .icon {
    width: 85px;
    height: 85px;
  }

  .floating-popup-login {
    width: 100%;
    height: 42px;
    margin-top: 14px;
    padding: 11px;

    @media screen and (max-width: 575px) {
      // mobile
      width: 100%;
      margin-top: 16px;
    }
  }

  .icon-div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
  }
}

.chevron {
  all: unset;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  width: 28px;
  height: 28px;
}
.title {
  color: #1c2a4b;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 8px;
}

.description {
  font-size: 15px;

  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: center;
  white-space: pre-line;

  span {
    font-weight: 500;
    color: #1f5af2;
  }
}
</style>
