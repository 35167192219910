import ASYNC_DATA_KEYS from '~/constants/async-data-keys';

import { notificationsService } from '../service';

export const useNotificationsCount = () => {
  return useAsyncDataWrapper(
    ASYNC_DATA_KEYS[`/v1/users/notifications/counts`](),
    async () => {
      const res = await notificationsService.getNotificationsCounts();
      return res;
    },
    { server: false },
  );
};
