import { safeDateParse } from '~/libs/dateUtils';

import type { Notification } from '../types';

export const sortNotificationsByPeriod = (notifications: Notification[]) => {
  const today = [];
  const recentSevenDays = [];
  const recentThirtyDays = [];
  const afterThirtyDays = [];

  for (const notification of notifications) {
    if (safeDateParse(notification.createdAt).isToday()) {
      today.push(notification);
    } else if (isRecentSevenDays(notification.createdAt)) {
      recentSevenDays.push(notification);
    } else if (isRecentThirtyDays(notification.createdAt)) {
      recentThirtyDays.push(notification);
    } else {
      afterThirtyDays.push(notification);
    }
  }

  return {
    today,
    recentSevenDays,
    recentThirtyDays,
    afterThirtyDays,
    all: notifications,
  };
};

const isRecentSevenDays = (date: string) => {
  const targetDate = safeDateParse(date);
  return targetDate.isAfter(safeDateParse(new Date()).subtract(7, 'days'));
};

const isRecentThirtyDays = (date: string) => {
  const targetDate = safeDateParse(date);
  return targetDate.isAfter(safeDateParse(new Date()).subtract(30, 'days'));
};
