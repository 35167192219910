<script setup lang="ts">
import { useNotificationsCount } from '../composables/useNotificationsCount';
import { provideNotificationsContext } from '../context';
import { notificationsService } from '../service';
import type { Notification, NotificationsCategorySelectableId } from '../types';
import { sortNotificationsByPeriod } from '../utils/sortNotificationsByPeriod';

const PAGE_SIZE = 30;

const route = useRoute();

const selectedCategory = ref<NotificationsCategorySelectableId>('ALL');

const page = ref<number>(1);

const pendingNotifications = ref(true);
const errorNotifications = ref<string>('');
const isLastPage = ref(false);
const notifications = ref<Notification[][]>([]);
const notificationsSorted = computed(() => sortNotificationsByPeriod(notifications.value.flat()));

const { data: unreadCountsData, refresh: refreshUnreadCounts } = await useNotificationsCount();
const unreadCounts = computed(() => unreadCountsData.value?.count ?? 0);

const fetchCurrentPage = async () => {
  try {
    const res = await notificationsService.getNotifications(
      page.value,
      PAGE_SIZE,
      selectedCategory?.value === 'ALL' ? undefined : selectedCategory?.value,
    );
    isLastPage.value = res.last;
    notifications.value[page.value - 1] = res.content;
    errorNotifications.value = '';
  } catch (err) {
    errorNotifications.value = (err as { data: { message: string } }).data.message;
  } finally {
    pendingNotifications.value = false;
  }
};

const refreshNotifications = async () => {
  const results = await Promise.all(
    [...Array(page.value).keys()].map((_, i) =>
      notificationsService.getNotifications(
        i + 1,
        PAGE_SIZE,
        selectedCategory?.value === 'ALL' ? undefined : selectedCategory?.value,
      ),
    ),
  );
  notifications.value = results.map((res) => res.content);
  errorNotifications.value = '';
};

const showNextPage = async () => {
  if (isLastPage.value) {
    return;
  }
  page.value++;
  fetchCurrentPage();
};

const changeCategory = async (newCategory: NotificationsCategorySelectableId) => {
  page.value = 1;
  selectedCategory.value = newCategory;
  try {
    const res = await notificationsService.getNotifications(
      page.value,
      PAGE_SIZE,
      selectedCategory?.value === 'ALL' ? undefined : selectedCategory?.value,
    );
    isLastPage.value = res.last;
    notifications.value = [res.content];
    errorNotifications.value = '';
  } catch (err) {
    errorNotifications.value = (err as { data: { message: string } }).data.message;
  }
};

provideNotificationsContext({
  notifications: notificationsSorted,
  unreadCounts,
  selectedCategory,
  isLastPage,
  pendingNotifications,
  errorNotifications,
  showNextPage,
  changeCategory,
  refreshNotifications,
  refreshUnreadCounts,
});

onMounted(() => {
  fetchCurrentPage();
});

watch(
  () => route.fullPath,
  () => {
    refreshUnreadCounts();
  },
);
</script>

<template>
  <slot />
</template>
