<script setup lang="ts">
import { useCommonStore } from '~/services/common';

import BasicToast from './BasicToast.vue';

type StoreToastType = typeof store.toast;
type State = {
  isShowing: boolean;
  component: StoreToastType['component'];
  text: StoreToastType['text'];
  subText: StoreToastType['subText'];
  iconEnabled: StoreToastType['icon'];
  clickPath: StoreToastType['clickPath'];
  align: StoreToastType['align'];
};

const router = useRouter();
const store = useCommonStore();

const state = reactive<State>({
  isShowing: false,
  text: '',
  component: null,
  subText: '',
  iconEnabled: false,
  clickPath: '',
  align: 'right',
});

watch(
  () => store.toast,
  (toast) => {
    state.isShowing = toast.enabled;
    state.text = toast.text ?? '';
    state.subText = toast.subText ?? '';
    state.iconEnabled = Boolean(toast.icon);
    state.clickPath = toast.clickPath ?? '';
    state.align = toast.align;

    state.component = toast.component || BasicToast;
    //=> 따로 컴포넌트를 전달하지 않으면 기존의 컴포넌트로
  },
);

const onClickToast = () => {
  if (state.clickPath) {
    router.push(state.clickPath);
  }
};
</script>

<template>
  <div class="snackbar" :class="{ show: state.isShowing }">
    <div class="container" :class="{ clickable: state.clickPath }" @click="onClickToast">
      <component :is="state.component" v-bind="state" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.snackbar {
  visibility: hidden;
  padding: 0 16px;
  position: fixed;
  z-index: 1400;
  font-size: 17px;
  width: 100%;
  bottom: 48px;
  left: 50%;
  display: flex;
  transform: translate(-50%, -50%);
}
.container {
  display: flex;

  border-radius: 4px;
  min-width: 1200px;
  max-width: 1200px;
  text-align: left;
  color: #fff;
  margin: 0 auto;
  padding: 0 10px;
  @media screen and (max-width: $md) {
    padding: 0;
    min-width: 100%;
  }

  p {
    display: flex;
    padding: 15px 20px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border-radius: 8px;
    min-width: 400px;
    background: #091e42;
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    @media screen and (max-width: $md) {
      padding: 12px 16px;
      font-size: 14px;
      min-width: unset;
      width: 100%;
      border-radius: 4px;
    }
  }
}

.clickable {
  cursor: pointer;
}

.snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 48px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    bottom: 130px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
</style>
