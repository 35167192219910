<script setup lang="ts">
import Tracker from '~/libs/Tracker';

import { useCommunityStore } from '~/services/community';
import { CommunityMainTabNumbers } from '~/services/main/constant';

const emit = defineEmits<{
  (e: 'menu-toggle', param: any): void;
}>();

const route = useRoute();
const communityStore = useCommunityStore();

const [communityBoards, { data: personalBoards }, boardHasNewContent] = await Promise.all([
  useCommunityBoards(),
  useCommunityPersonalBoards(),
  useBoardHasNewContent(),
]);

const sortedCommunityBoards = computed(() => {
  return communityBoards.value.sort((a, b) => {
    if (a.boardCategoryCd === '100140') {
      if (b.boardCategoryCd === '100140') {
        return a.position - b.position;
      }

      return -1;
    }

    // 월부챌 게시판만 전문가 칼럼 카테고리 바로 아래 노출
    if (a.boardSeq === 33) {
      return b.boardCategoryCd === '100140' ? 1 : -1;
    }

    return 0;
  });
});

const setIsCurrentTab = (boardCategoryCd: string, boardSeq: number | null) => {
  if (boardSeq) {
    return boardSeq.toString() === (route.query.subTab as string);
  } else {
    return boardCategoryCd === (route.query.tab as string);
  }
};

const trackHamburgerEvent = (boardSeq: any, boardName: string) => {
  Tracker['Click Hamburger Category']({
    tabName: '커뮤니티',
    categoryId: boardSeq,
    text: boardName,
  });
};

const onClickListTab = (boardName: string, boardCategoryCd: string, boardSeq: number | null, e: any) => {
  const isCurrentTab = setIsCurrentTab(boardCategoryCd, boardSeq);
  if (isCurrentTab) {
    emit('menu-toggle', e);
  } else {
    trackHamburgerEvent(boardSeq, boardName);
  }
};

const navExceptCurrentPath = (boardCategoryCd: string, boardSeq: number | null) => {
  const isCurrentTab = setIsCurrentTab(boardCategoryCd, boardSeq);
  if (isCurrentTab) {
    return null;
  }
  return communityStore.setSubNavigation(boardCategoryCd, boardSeq);
};
</script>

<template>
  <div class="community-link-list-item-wrapper px-4 pb-3">
    <NuxtLink
      class="flex py-2.5 cursor-pointer basis-full text-sm font-medium text-neutral-1000"
      :to="navExceptCurrentPath(CommunityMainTabNumbers.HOME, null)"
      @click="
        (e) => {
          onClickListTab('홈', CommunityMainTabNumbers.HOME, null, e);
        }
      ">
      커뮤니티홈
    </NuxtLink>
    <!-- <NuxtLink
      v-if="userAuthStore.user"
      class="community-link-list-item"
      :to="navExceptCurrentPath(CommunityMainTabNumbers.FOLLOW, null)"
      @click="
        (e) => {
          onClickListTab('팔로우', CommunityMainTabNumbers.FOLLOW, null, e);
        }
      "
    >
      팔로우
    </NuxtLink> -->
    <NuxtLink
      v-for="item in sortedCommunityBoards"
      :key="item.boardSeq"
      class="flex basis-1/2 py-2.5 cursor-pointer text-sm font-medium text-neutral-1000"
      :to="navExceptCurrentPath(item.boardCategoryCd, item.boardSeq as number)"
      @click="
        (e) => {
          onClickListTab(item.boardName, item.boardCategoryCd, item.boardSeq as number, e);
        }
      ">
      <span class="community-link-list-item-text">
        {{ item.boardName }}
        <span v-if="boardHasNewContent(Number(item.boardSeq))" class="red-dot" />
      </span>
    </NuxtLink>
    <NuxtLink
      v-for="item in personalBoards"
      :key="item.boardSeq"
      class="flex basis-1/2 py-2.5 cursor-pointer text-sm font-medium text-neutral-1000"
      :to="navExceptCurrentPath(item.boardCategoryCd, item.boardSeq as number)"
      @click="
        (e) => {
          onClickListTab(item.boardName, item.boardCategoryCd, item.boardSeq as number, e);
        }
      ">
      {{ item.boardName }}
    </NuxtLink>
  </div>
</template>

<style lang="scss" scoped>
.community-link-list-item-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: 18px;
}

.community-link-list-item-text {
  position: relative;
}

.red-dot {
  position: absolute;
  top: 3px;
  right: -7px;
  background-color: #e34935;
  width: 4px;
  height: 4px;
  border-radius: 50%;
}
</style>
