<script setup lang="ts">
import Tracker from '~/libs/Tracker';

import { useUserGnbStore } from '~/services/gnb';
import { useMainStore } from '~/services/main';
import { useUserAuthStore } from '~/services/userAuth/store';

const store = useUserGnbStore();
const authStore = useUserAuthStore();
const mainStore = useMainStore();
const route = useRoute();

const isEmpty = $isEmpty;
const isCategory = ref(false);

const isRootPath = () => {
  return route.path === '/';
};

const { isDesktop } = useMobile();

const gnbMenu = computed(() => {
  return mainStore.gnbMenuList || [];
});

const isLoggedIn = computed(() => {
  return !$isEmpty(authStore.user);
});

const isActiveGnb = (url) => {
  if (url && ($url.isWeolbuUrl(url) || url.startsWith('/'))) {
    const path = $url.parseUrl(url);
    const nowPath = $url.parseUrl(route.fullPath);

    if (path?.pathname === nowPath?.pathname && path?.search === nowPath?.search) {
      return true;
    }
  }
  return false;
};

// GNB 메뉴 클릭시 이벤트
const handleGnbMenuClick = (item) => {
  // console.log('handleGnbMenuClick', item);
  // 이벤트 트래킹
  Tracker['Click Navigation']({
    menuType: 'GNB',
    pageName: item.title,
    url: item.linkUrl,
  });
};

const scroll = () => {
  handleCategoryMouseLeave();
};

const handleCategoryMouseEnter = () => {
  isCategory.value = true;
  // document.body.style.overflow = 'hidden';
  window.addEventListener('scroll', scroll, { once: true });
};

const handleCategoryMouseLeave = () => {
  isCategory.value = false;
  // document.body.style.overflow = '';
  window.removeEventListener('scroll', scroll, { once: true });
};

const handleCategoryClick = (item) => {
  handleCategoryMouseLeave();
  Tracker['Click Navigation']({
    pageName: item.cateName,
    url: item.to,
  });
};

const handleClickEvent = (btnTitle) => {
  Tracker['Click Navigation']({
    pageName: btnTitle,
    menuType: 'GNB',
  });
};

onServerPrefetch(async () => {
  await store.fetchUserGnbList();
});
onBeforeMount(async () => {
  if (store.categorys.length === 0) {
    await store.fetchUserGnbList();
  }
});
</script>
<template>
  <div class="wb-header-gnb">
    <div v-if="isEmpty(gnbMenu)" class="wb-header-gnb-skeleton flex gap-2">
      <Skeleton v-for="(_, i) in Array.from({ length: 10 })" :key="i" height="38px" class="mb-2" />
    </div>
    <menu v-else class="wb-header-gnb-slide" :class="{ 'wb-header-gnb-bottom': !isRootPath() }">
      <div class="wb-inner flex items-center gap-2">
        <Swiper
          :slides-per-view="'auto'"
          @click="
            (swiper) => {
              // console.log('swiper.clickedIndex', swiper.clickedIndex);
              swiper.slideTo(swiper.clickedIndex - 1);
            }
          "
          @afterInit="
            (el) => {
              gnbMenu.forEach((item, index) => {
                if (isActiveGnb(item.linkUrl)) {
                  el.slideTo(index, 0, false);
                }
              });
            }
          ">
          <SwiperSlide @mouseenter="handleCategoryMouseEnter" @mouseleave="handleCategoryMouseLeave">
            <div class="wb-header-gnb__item category-item">
              <a :class="{ active: isCategory }" @click.capture.prevent.stop="() => {}">
                <MenuIcon />
                카테고리
              </a>
            </div>
          </SwiperSlide>
          <!-- wb-header-gnb__item--new 도 있는데 현재 쓰이지 않음 -->
          <!-- GNB 메뉴 -->
          <SwiperSlide v-for="item in gnbMenu" :key="item.title">
            <div
              class="wb-header-gnb__item"
              :class="{
                'wb-header-gnb__item--void-badge': item.isExistedBadge,
              }">
              <CustomLink
                :class="{ active: isActiveGnb(item.linkUrl) }"
                :prefetch="false"
                :to="item.linkUrl"
                :target="item.linkType === 'N' ? '_blank' : ''"
                @click="handleGnbMenuClick(item)"
                >{{ item.title }}</CustomLink
              >
            </div>
          </SwiperSlide>
        </Swiper>
        <div v-if="isDesktop" class="flex flex-shrink-0 items-center">
          <a
            class="text-neutral-700 font-medium px-2.5 py-3"
            href="https://b2b.weolbu.com"
            target="_blank"
            @click="handleClickEvent('기업교육')">
            기업교육
          </a>
          <template v-if="!isLoggedIn">
            <span class="text-[#eee] font-light">ㅣ</span>
            <a
              class="text-neutral-700 font-medium px-2.5 py-3"
              href="https://docs.google.com/forms/d/e/1FAIpQLScfpPlTQVkq1HG6qqnOvc2AJEFFu1mqrS61KamxMDR57mxpsA/viewform"
              target="_blank"
              @click="handleClickEvent('강사지원하기')">
              강사 지원하기
            </a>
          </template>
        </div>
      </div>
      <div
        class="wb-header-gnb-category"
        :class="{ active: isCategory }"
        @mouseenter="handleCategoryMouseEnter"
        @mouseleave="handleCategoryMouseLeave">
        <div class="wb-inner">
          <div v-for="category in store.getUserGnbTree" :key="category.cateSeq" class="wb-header-gnb-category-item">
            <CustomLink :to="category.to" class="wb-header-gnb-category-title" @click="handleCategoryClick(category.to)"
              >{{ category.cateName }}
              <ClientOnly>
                <ChevronRightIcon class="icon-color" />
              </ClientOnly>
            </CustomLink>
            <!-- <ul class="wb-header-gnb-category-list">
              <li v-for="catItem in category.items" :key="catItem.cateSeq" class="wb-header-gnb-category-list-item">
                <CustomLink :to="catItem.to" @click="handleCategoryClick(catItem)">{{ catItem.cateName }}</CustomLink>
              </li>
            </ul> -->
            <div class="wb-header-gnb-category-list">
              <CustomLink
                v-for="catItem in category.items"
                :key="catItem.cateSeq"
                class="wb-header-gnb-category-list-item"
                :to="catItem.to"
                @click="handleCategoryClick(catItem)"
                >{{ catItem.cateName }}</CustomLink
              >
            </div>
          </div>
        </div>
      </div>
      <div v-if="isCategory" class="wb-header-gnb-category-dim" />
    </menu>
  </div>
</template>

<style lang="scss" scoped>
.layout-product {
  .wb-header-gnb {
    position: relative;
    top: auto;
    z-index: 999;
  }
}
.layout-hide-gnb {
  .wb-header-gnb {
    display: none;
  }
}
.wb-header-gnb {
  position: sticky;
  top: 58px;
  margin: -16px 0 0 0;
  background: #fff;
  z-index: 1000;
  padding: 8px 0 0px 0;
  @media screen and (max-width: $mobile) {
    padding: 0;
    margin: 0;
  }
  &-slide {
    padding: 0;
    margin: 0;
  }
  .wb-inner {
    @media screen and (max-width: $mobile) {
      padding: 0;
    }
  }
  &__item {
    padding: 0 12px;
    font-size: 0; // ios 대응
    @media screen and (max-width: $mobile) {
      padding: 0 8px;
    }
    &.category-item {
      margin-left: 4px;
      i {
        position: absolute;
        left: -1px;
        top: 12px;
        width: 20px;
        height: 20px;
        color: #051130;
      }
      a {
        padding-left: 20px;
        position: relative;
        cursor: pointer;
      }
      @media screen and (max-width: $mobile) {
        display: none;
      }
    }
    &--void-badge {
      position: relative;
      ::before {
        content: '';
        position: absolute;
        top: 2px;
        right: 4px;
        width: 5px;
        height: 5px;
        background-color: #e34935;
        border-radius: 50%;
        @media screen and (max-width: $mobile) {
          top: 3px;
          right: 2px;
          width: 4px;
          height: 4px;
          a {
            padding-right: 10px;
          }
        }
      }
    }
    &--circle-area {
      position: relative;

      .circle-red {
        position: absolute;
        top: 1px;
        right: 8px;
        width: 5px;
        height: 5px;
        background-color: #e34935;
        border-radius: 50%;
        opacity: 90%;
      }
    }
    a {
      padding: 12px 0;
      font-weight: 600;
      font-size: 16px;
      color: #051130;
      white-space: nowrap;
      box-sizing: border-box;
      @media screen and (max-width: $mobile) {
        padding: 9px 0;
        font-size: 14px;
        line-height: 18px;
      }

      &.active,
      &:hover {
        border-bottom: 2px solid #1c2a4b;
      }
    }
  }

  &-bottom {
    border-bottom: 1px solid #f1f2f4;
  }
  .swiper {
    margin-left: -16px;
    @media screen and (max-width: $mobile) {
      margin: 0;
      padding-left: 8px;
    }
    .swiper-slide {
      display: flex;
      align-items: center;
      height: 46px;
      width: auto !important;
      @media screen and (max-width: $mobile) {
        height: 40px;
        padding: 0;
      }
    }
  }
}
.wb-header-gnb-category {
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 999;
  transform: translateY(-100%);
  opacity: 0;
  // visibility: hidden;
  display: none;
  // transition: transform 3s ease-in-out, opacity 3s ease-in-out;
  margin: 0 auto;
  padding: 24px 0 40px 0;
  &.active {
    // visibility: visible;
    display: block;
    transform: translateY(0);
    opacity: 1;
  }
  // &.active {
  //   animation: slideGnbCategory 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
  //   animation-fill-mode: forwards;
  // }
  .wb-inner {
    display: flex;
    flex-direction: row;
    .wb-header-gnb-category-item {
      background: #fff;
      display: flex;
      min-width: 140px;
      padding: 8px 8px;
      flex-direction: column;
      border-right: 1px solid #f1f2f4;
      cursor: pointer;
      // margin: 0 8px;
      &:first-child {
        background: #eef3ff;
        border-radius: 4px;
        border-right: 0;
        padding: 8px 0px;
        margin: 0;
        .wb-header-gnb-category-title,
        .wb-header-gnb-category-list-item {
          &:hover {
            background: #d1ddfc;
          }
        }
      }
      .wb-header-gnb-category-title {
        width: 100%;
        display: flex;
        height: 36px;
        padding: 10px 16px;
        align-items: center;
        gap: 8px;
        justify-content: space-between;
        color: #091227;

        font-size: 14px;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        &:hover {
          background: #f1f2f4;
        }
      }
      .wb-header-gnb-category-list {
        width: 100%;
      }
      .wb-header-gnb-category-list-item {
        width: 100%;
        display: block;
        padding: 8px 16px;
        align-items: center;
        gap: 8px;

        color: #48546e;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover {
          background: #f1f2f4;
        }
      }
    }
  }
}
.wb-header-gnb-category-dim {
  position: absolute;
  width: 100%;
  height: 100vh;
  opacity: 0.6;
  background: #000;
  // z-index: 998;
}
// @keyframes slideGnbCategory {
//   from {
//     transform: translateY(-100%);
//     opacity: 0;
//     visibility: hidden;
//   }
//   30% {
//     transform: translateY(-100%);
//     opacity: 0;
//     visibility: hidden;
//   }
//   to {
//     visibility: visible;
//     transform: translateY(0);
//     opacity: 1;
//   }
// }
// .wb-header-gnb__item--circle-area {
//   position: relative;

//   .circle-red {
//     position: absolute;
//     top: 1px;
//     right: 8px;
//     width: 5px;
//     height: 5px;
//     background-color: #e34935;
//     border-radius: 50%;
//     opacity: 90%;
//   }
// }
.icon-color {
  width: 10px;
  color: #1c2a4b;
}
</style>
