<script setup lang="ts">
import Tracker from '~/libs/Tracker';
import { requestPermission } from '~/libs/firebaseConfig';

import { mainService } from '../../main';

const isOpened = ref(true);

const { isPwa, os } = usePwa();

onMounted(() => {
  Tracker['View Bottom Sheet']({
    bottomSheetType: 'set_notification',
  });
});

const onClose = () => {
  isOpened.value = false;
  updatePWAPopupPeriod('showNotificationPopup', 7);
};

const onClickCTA = async () => {
  isOpened.value = false;

  const token = await requestPermission();
  if (token) {
    await mainService.postUserDeviceToken({
      deviceToken: token,
      os: os.value,
      isPwa: isPwa.value,
    });
  }

  Tracker['Click Bottom Sheet']({
    bottomSheetType: 'set_notification',
    buttonType: 'confirm',
    buttonName: '알림 받아보기',
  });
};
</script>

<template>
  <Dialog v-model:visible="isOpened" modal class="wb-dialog wb-dialog--bottom">
    <div class="wb-dialog__close">
      <Button icon="pi pi-times" text rounded @click="onClose" />
    </div>
    <div class="container">
      <img class="bell" src="/images/notification-permission-bell.png" />
      <strong>알림을 켜주세요!</strong>
      <p><span>전문가 칼럼, 팔로우 멤버 새글 소식, 댓글</span> 등</p>
      <p>서비스 이용에 필요한 알림을 보내드릴게요.</p>
      <p class="description">언제든지 설정 > 애플리케이션 정보에서 변경하실 수 있어요.</p>
      <Button label="알림 받아보기" class="w-full" @click="onClickCTA" />
    </div>
  </Dialog>
</template>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
  padding: 0 16px 20px;

  button {
    margin-top: 24px;
    margin-bottom: 20px;
  }
}

.bell {
  width: 80px;
  height: 80px;
  margin-top: 40px;
}

strong {
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.011em;
  text-align: center;
  margin-bottom: 8px;
}

p {
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #1c2a4b;

  span {
    color: #1f5af2;
  }

  &.description {
    font-size: 13px;
    line-height: 16px;
    color: #656f85;
    margin-top: 8px;
  }
}
</style>
