import dayjs from 'dayjs';
import { isEmpty } from 'lodash-es';
import { safeDateParse } from '~~/src/libs/dateUtils';
import { safeLocalStorage } from '~~/src/libs/safeLocalStorage';
import {
  EXCEPTION_PATH,
  UTM_KEY,
  getCashworkUserInfo,
  getTestableDisplaySeq,
  getTestableDueDate,
  setCashworkUserInfo,
} from '~~/src/services/event/constants/cashwalk2410';
import { useUserProfileStore } from '~~/src/services/profile';
import { useUserAuthStore } from '~~/src/services/userAuth';

/**
 * 10월 25일 이후 삭제
 */
export const useCashwalkTr = () => {
  const route = useRoute();
  const userAuthstore = useUserAuthStore();
  const profileStore = useUserProfileStore();

  const now = safeDateParse(dayjs());
  const due = getTestableDueDate(safeLocalStorage.getItem('cashwalk-due'));

  const targetDisplaySeq = getTestableDisplaySeq(safeLocalStorage.getItem('cashwalk-seq'));

  /**
   * 캐시워크 기간이며
   * utm_source=캐시워크로 진입한 기록이 로컬스토리지에 남아있으며
   * 구매 이력이 없거나 비로그인 유저
   * 재방문 여부 (분단위로 비교)
   *
   * 위 4조건을 만족할 때, 캐시워크용 TR 다이얼로그를 노출한다.
   */
  const isCashworkTrTarget = computed(() => {
    const visitInfo = getCashworkUserInfo();

    const isCashworkDuration = now.isBefore(due, 'minutes');
    const isCashworkUser = !isEmpty(visitInfo);
    const isZeroOrGuestUser = profileStore.profile?.paymentFunnel === 'ZERO' || isEmpty(userAuthstore.user);
    const isRevisit = !!visitInfo?.firstVisit && now.isAfter(safeDateParse(visitInfo.firstVisit), 'minutes');
    const isNotTargetRoute = Number(route.query?.displaySeq) !== Number(targetDisplaySeq);

    const isNotExceptionPath = !EXCEPTION_PATH.includes(route.path);

    return (
      isCashworkDuration && isCashworkUser && isZeroOrGuestUser && isRevisit && isNotTargetRoute && isNotExceptionPath
    );
  });

  onMounted(() => {
    if (
      String(route.query.utm_source).toLowerCase() !== UTM_KEY.toLowerCase() ||
      now.isAfter(due) ||
      getCashworkUserInfo()
    ) {
      return;
    }

    setCashworkUserInfo(now.format('YYYY-MM-DD HH:mm'));
  });

  return { isCashworkTrTarget };
};
